// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.websiteFooterTable
{
   background-color: #484848; 
   font-family: 'Roboto'; 
   color: white !important; 
   border: none !important; 
}

#websiteFooterToolbar{
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    background-color: #484848;
}

#websiteFooter{ 
    border: none; 
    border-radius: 10px;
}

#websiteFooter ul li {
    list-style-type: none;
    background-color: #484848;
    font-family: 'Roboto';
    color: white !important;
    vertical-align: middle;
} 

a{
    color: white;
}

#copyRights{
    color: white !important; 
    background-color: #484848;
}

#connectedMeText{
    color: white;
}

#socialMediaLinks{
    color: white; 
}

.tableRow{
    border-bottom: none !important;
}

.sendMeMessage{
    p:hover{
        cursor: grab;
    }
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/footer/websitefooter.css"],"names":[],"mappings":"AAAA;;GAEG,yBAAyB;GACzB,qBAAqB;GACrB,uBAAuB;GACvB,uBAAuB;AAC1B;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;IACzB,qBAAqB;IACrB,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".websiteFooterTable\n{\n   background-color: #484848; \n   font-family: 'Roboto'; \n   color: white !important; \n   border: none !important; \n}\n\n#websiteFooterToolbar{\n    color: white;\n    border: 1px solid white;\n    border-radius: 10px;\n    background-color: #484848;\n}\n\n#websiteFooter{ \n    border: none; \n    border-radius: 10px;\n}\n\n#websiteFooter ul li {\n    list-style-type: none;\n    background-color: #484848;\n    font-family: 'Roboto';\n    color: white !important;\n    vertical-align: middle;\n} \n\na{\n    color: white;\n}\n\n#copyRights{\n    color: white !important; \n    background-color: #484848;\n}\n\n#connectedMeText{\n    color: white;\n}\n\n#socialMediaLinks{\n    color: white; \n}\n\n.tableRow{\n    border-bottom: none !important;\n}\n\n.sendMeMessage{\n    p:hover{\n        cursor: grab;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import { Table, TableBody, TableCell, TableRow, Toolbar, Typography } from "@mui/material";
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import './websitefooter.css';

const WebsiteFooter = () => {


    const [openModals, setOpenModals] = useState(false);

    const openModal = (modalId) => {
        setOpenModals((prevOpenModals) => ({ ...prevOpenModals, [modalId]: true }));
    };

    const closeModal = (modalId) => {
        setOpenModals((prevOpenModals) => ({ ...prevOpenModals, [modalId]: false }));
    };

    const [formErrors, setFormErrors] = useState({});

    const [values, setValues] = useState({
        contactMeMessage: ""
    });

    function handleSubmit(e) {
        e.preventDefault();
        console.log("Sending message.");
        sendMessage(values);
    }



    const handleMessageChange = (event) => {
        setValues({ ...values, contactMeMessage: event.target.value })
    }

    function sendMessage(expense) {
        const EXPENSES_URL = 'http://localhost:8080/blogOps/contactMe';
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body:
                JSON.stringify({
                    "contact-me-message": expense.contactMeMessage
                })

        }

        fetch(EXPENSES_URL, requestOptions)
            .then(res => res.json())
            .then(data => {
                console.log("Message sent.");
            }).catch(error => {
                console.log(error.toString());
            });

        closeModal('contactModal');
    }

    return (
        <>
            <Toolbar id="websiteFooterToolbar">
                <div id="connectedMeText">
                    Get connected with me on.
                </div>

                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    {/* Gap between the social media links and connected me text.*/}
                </Typography>

                <div id="socialMediaLinks">
                    <a href="https://www.instagram.com/abizsrm" target="_blank" >
                        <InstagramIcon fontSize='large' />
                    </a>

                    &nbsp;
                    <a href="https://github.com/abiZsrm" target="_blank" >
                        <GitHubIcon fontSize='large' />
                    </a>

                    &nbsp;
                    <a href="https://www.linkedin.com/in/abizsrm/" target="_blank">
                        <LinkedInIcon fontSize='large' />
                    </a>
                </div>
            </Toolbar>
            <Table id="websiteFooter">

                <TableBody>
                    <TableRow className="tableRow">
                        <TableCell align="center" className="websiteFooterTable" >
                            TECHNOLOGIES USED
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            INSPIRED BY
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            CONNECT
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            HIRE ME !
                        </TableCell>
                    </TableRow>
                    <TableRow className="tableRow">
                        <TableCell align="center" className="websiteFooterTable">
                            <ul>
                                <li> REACT </li>
                                <li> JAVA </li>
                                <li> SPRING </li>
                                <li> HTML </li>
                                <li> CSS </li>
                                <li> JAVASCRIPT </li>
                            </ul>
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            <ul>
                                <li><a href="https://getmdl.io/templates/android-dot-com/index.html">Android.com Google MDL</a></li>
                            </ul>
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            <table align="center">
                                <tbody>
                                    <tr>
                                        <td>
                                            <ContactMailIcon style={{ cursor: "pointer" }} onClick={() => openModal('contactModal')} />
                                        </td>
                                        <td onClick={() => openModal('contactModal')}>
                                            <p style={{ cursor: "pointer" }}>Send me a message.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <Modal open={openModals['contactModal'] == null ? false : openModals['contactModal']}
                                onClose={() => closeModal('contactModal')}>
                                <ModalDialog layout="center">
                                    <ModalClose />
                                    <Box>
                                        <form onSubmit={handleSubmit}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Name:
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type='text'
                                                                name='name'
                                                                minLength='1'
                                                                maxLength='50' />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span id="nameAddSpan">
                                                                {formErrors.name}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Email:
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <input
                                                                type='text'
                                                                name='name'
                                                                minLength='1'
                                                                maxLength='50' />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span id="nameAddSpan">
                                                                {formErrors.name}
                                                            </span>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>
                                                            Description:
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <textarea
                                                                onChange={handleMessageChange}
                                                                type='text'
                                                                name='description' />

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <span id="descriptionAddSpan">
                                                                {formErrors.description}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect" type="submit">SUBMIT </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </form>
                                    </Box>
                                </ModalDialog>
                            </Modal>
                        </TableCell>

                        <TableCell align="center" className="websiteFooterTable">
                            <table align="center">
                                <tbody>
                                    <tr>
                                        <td>
                                            <ContactPageIcon fontSize="large" />
                                        </td>
                                        <td>
                                            View Resume
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <div align="right" id="copyRights">
                <br />
                <b>Copyright © 2024-2029 Abhishek Madhusudhan. All Rights Reserved. &nbsp; &nbsp;</b>
                <br />
                <br />
            </div>
        </>
    )
}

export default WebsiteFooter; 
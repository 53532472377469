// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_ContainerStyle__a6hRr
{
	width: 10vmin;
	height: 10vmin;

	border-radius: 50%;

	font-size: 3vmin;

	background-color: var(--bubble-background);

	transition: 0.2s transform;
	z-index: 100000;

	outline: none;
	font-weight: 600;
	font-size: 0.9rem;

	text-align: center;

	color: var(--bubble-foreground);

	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	animation: style_created__szFrf 0.2s;
}

.style_ContainerStyle__a6hRr:hover
{
	transform: scale(1.1);
}

@keyframes style_created__szFrf {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/timeline/bubbles/molecules/bubble/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;CACb,cAAc;;CAEd,kBAAkB;;CAElB,gBAAgB;;CAEhB,0CAA0C;;CAE1C,0BAA0B;CAC1B,eAAe;;CAEf,aAAa;CACb,gBAAgB;CAChB,iBAAiB;;CAEjB,kBAAkB;;CAElB,+BAA+B;;CAE/B,kBAAkB;;CAElB,aAAa;CACb,mBAAmB;CACnB,uBAAuB;;CAEvB,oCAAuB;AACxB;;AAEA;;CAEC,qBAAqB;AACtB;;AAEA;CACC;EACC,mBAAmB;CACpB;CACA;EACC,mBAAmB;CACpB;AACD","sourcesContent":[".ContainerStyle\n{\n\twidth: 10vmin;\n\theight: 10vmin;\n\n\tborder-radius: 50%;\n\n\tfont-size: 3vmin;\n\n\tbackground-color: var(--bubble-background);\n\n\ttransition: 0.2s transform;\n\tz-index: 100000;\n\n\toutline: none;\n\tfont-weight: 600;\n\tfont-size: 0.9rem;\n\n\ttext-align: center;\n\n\tcolor: var(--bubble-foreground);\n\n\tposition: relative;\n\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\tanimation: created 0.2s;\n}\n\n.ContainerStyle:hover\n{\n\ttransform: scale(1.1);\n}\n\n@keyframes created {\n\tfrom {\n\t\ttransform: scale(0);\n\t}\n\tto {\n\t\ttransform: scale(1);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerStyle": `style_ContainerStyle__a6hRr`,
	"created": `style_created__szFrf`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

// My website components. 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import NavigationBar from './components/NavigationBar';
import Employers from './components/employers/Employers';
import EmploymentEducationHistory from './components/employmentEducationHistory/EmploymentEducationHistory';
import WebsiteFooter from './components/footer/WebsiteFooter';
import Home from './components/home/Home';
import CertsAndAwards from './components/profCertsAndAwards/CertsAndAwards';
import Projects from './components/projects/Projects';
import Publication from './components/publication/Publication';
import Testimonials from './components/testimonials/Testimonials';

const Portfolio = () => {

    return (
        <>
        {/* Navigation Bar */}
        <NavigationBar />

        <br />
        <br />
        <br />
        <br />

        {/* Home */}
        <Home />

        {/* Employment & Education */}
        <EmploymentEducationHistory />

        {/* Professional Certifications & Awards */}
        <CertsAndAwards />

         {/* Projects */}
         <Projects /> 
         
         {/* Publications */}
        <Publication />

        {/* Testimonials */}
        <Testimonials />

        {/* TODO: Skills */}

        {/* Employers */}
        <Employers/>
        
        {/* Website Footer */}
        <Box id="websiteFooter" component={Paper}>
          <WebsiteFooter />
        </Box> 

        {/* */}
        </>
    )
}

export default Portfolio; 
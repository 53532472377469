import React, { useEffect, useState } from 'react';
import {
  AlignDropdown,
  BackgroundColorPicker,
  BoldButton,
  CodeFormatButton,
  Divider,
  Editor,
  EditorComposer,
  FontFamilyDropdown,
  FontSizeDropdown,
  InsertDropdown,
  InsertLinkButton,
  ItalicButton,
  TextColorPicker,
  TextFormatDropdown,
  ToolbarPlugin,
  UnderlineButton
} from 'verbum';

import '../editor/Editor.css';

const EditorVerbumForAddPost = (props) =>  {

  /**
   * Updating preview content and editor state. 
   * 
   * @param {EditorState} editorState 
   * @param {EditorInstance} editorInstance 
   */
  function onChange(editorState){

    // Update the preview content. 
    const editorContent = "<br /> " + document.querySelector('.ContentEditable__root').innerHTML + "<br />"; 
    props.setContent(editorContent); 

    // Update the new editor state everytime the state updates. 
    props.changeEditorState(editorState); 
  };


  return (
    <EditorComposer  >
      <div className="editor-container">
        <Editor hashtagsEnabled={true} emojisEnabled={true}  onChange={onChange} >
          <ToolbarPlugin defaultFontSize="20px">
            <FontFamilyDropdown />
            <FontSizeDropdown />
            <Divider />
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <CodeFormatButton />
            <InsertLinkButton />
            <TextColorPicker />
            <BackgroundColorPicker />
            <TextFormatDropdown />
            <Divider />
            <InsertDropdown enablePoll={true} />
            <Divider />
            <AlignDropdown />
          </ToolbarPlugin>
        </Editor>
      </div>
    </EditorComposer>
  );
};

export default EditorVerbumForAddPost; 
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlogAPI } from '../../service/BlogAPI';
import EditorVerbumForAddPost from './editor/EditorVerbumForAddPost';
import PreviewPost from './editor/PreviewPost';

// Import styles. 
import '../post/editor/Editor.css';

const AddPost = () => {

  
  // const user = useUser(); 

  const [uniqueId, setUniqueId] = useState();
  const [content, setContent] = React.useState({})
  const [formErrors, setFormErrors] = useState({});
  const [values, setValues] = useState({
    title: "",
    type: ""
  });
  const [newEditorState, setNewEditorState] = useState();



  const navigate = useNavigate();


  useEffect(() => { 
    
    // generateUniqueId(); 
  
   
  
  }, []);

  /**
   * function to handle article title change. 
   * 
   * @param {EVENT} event 
   */
  function handleArticleTitleChange(event) {
    setValues({ ...values, title: event.target.value });
  }

  /**
   * function to handle article title change. 
   * 
   * @param {EVENT} event 
   */
  function handleArticleTypeChange(event) {
    setValues({ ...values, type: event.target.value });
  }

  /**
   * function to generate unique id. 
   */
  function generateUniqueId() {
    const UNIQUE_ID_URL = 'http://localhost:8080/utilityOps/uniqueId';

    Promise.resolve(fetch(UNIQUE_ID_URL))
      .then(res => res.json())
      .then(data => {
        setUniqueId(data);
      }).catch(error => {
        console.log(error.toString());
      });
  }

  /**
   * function to add article object. 
   * 
   * @param {Article Obj} articleObj 
   */
  function addArticle(articleObj) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:
        JSON.stringify({
          "article-id": uniqueId,
          "article-title": articleObj.title,
          "article-category": articleObj.type,
          "article-content": content, 
          "article-editorState": newEditorState
        })
    }

    BlogAPI.addArticle(requestOptions)
      .then(data => {
      }).catch(error => {
        console.log(error.toString());
      });
  }


  /**
  * Function to submit post content to the database. 
  */
  function handleOnClick() {
    // TODO: Write a validator before you attempt to add article. 
    
    addArticle(values);

    navigate("/Blog"); 
  }

  /**
   * Function to cancel the post content to the database. 
   */
  function handleCancel() { 
    navigate("/Blog"); 
  }

   /**
     * function to change the editor state of the page. 
     * 
     * @param {EditorState String} editorState 
     */
   function changeEditorState(editorState) {
    setNewEditorState(editorState);
}

// console.log("User logged in"); 
// console.log(user.isLoggedIn); 
// if(!user)
// {
//   window.location.href = "http://localhost:8080/"

// }


  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#484848" }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            TECHNICAL BLOG CMS
          </Typography>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {/* Gap between different navigation heads and menu icon*/}
          </Typography>

          {/* TODO: Dynamically retrieve the url and assign it. Don't keep it static */}
          <a className='navElements' href={process.env.REACT_APP_FRONT_END_BASE_URL}>
            <Button color="inherit" style={{ color: "white" }} className='navElements'>HOME</Button>
          </a>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <br />
      <br />

      <table align="center">
        <tbody>
          <tr>
            <td>
              <Typography variant="h6" noWrap component="div" >
                Title
              </Typography>
            </td>
            <td>
              Type
            </td>

          </tr>
          <tr>
            <td>
              <OutlinedInput
                id="titleInput"
                label="Title"
                onChange={handleArticleTitleChange}
                style={{ backgroundColor: 'white', width: '750px', borderRadius: '10px' }}
              />
            </td>
            <td>
              {/* TODO: Get all the types. */}
              <Select
                id="articleTypeSelect"
                onChange={handleArticleTypeChange}
                style={{ width: '250px', backgroundColor: 'white', borderRadius: '10px' }}>
                <MenuItem value="JAVA">JAVA</MenuItem>
                <MenuItem value="REACT">REACT</MenuItem>
                <MenuItem value="KAFKA">KAFKA</MenuItem>
              </Select>
            </td>
          </tr>
          <tr>
            <td>
              <span id="titleEditSpan">
                {formErrors.name}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <EditorVerbumForAddPost changeEditorState={changeEditorState} setContent={setContent} />

      <Typography variant="h6" noWrap component="div" align="center">
        Preview
      </Typography>

      <PreviewPost className="editor-container" content={content} />

      <Box textAlign='center'>
        <Button variant="contained" onClick={handleOnClick}> Submit </Button>
        &nbsp; &nbsp;
        <Button variant="contained" onClick={handleCancel}> Cancel </Button>
      </Box>
    </>
  )
}

export default AddPost; 
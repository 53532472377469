import React, { createContext, useState, useContext } from 'react';
import { getCurrentUser } from './APIUtils';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("accessToken"));

    const login = () => {
        getCurrentUser()
            .then(data => {
                console.log("Current User.");
                console.log(data);
                let something = data.id != null;
                console.log(something);
                setIsLoggedIn(true)
            })
    };


    const logout = async () => {
        localStorage.removeItem("access_token");
        setIsLoggedIn(false);
    };


    return (
        <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
         {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider; 
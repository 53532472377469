// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Github Icon */
.headerzindex{
    z-index: 10 !important;
 }
.githubButton:hover {
    color: black !important;
}

.githubButtonSize {
    font-size: 50px;
    color: white;
    margin-right: 10px;
}

/* LinkedIn Icon */ 
.linkedInButton:hover{
    color: #0077b5 !important;
    background-size: cover;
}

.linkedInButtonSize {
    font-size: 50px; 
    color: white; 
    margin-right: 10px;
}

/* Navigation elements underline*/
.navElements:hover{ 
    text-decoration: underline !important;
    text-decoration-color: white !important;
    text-underline-offset: 5px;
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/navigation-bar.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;IACI,sBAAsB;CACzB;AACD;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA,kBAAkB;AAClB;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA,iCAAiC;AACjC;IACI,qCAAqC;IACrC,uCAAuC;IACvC,0BAA0B;AAC9B","sourcesContent":["/* Github Icon */\n.headerzindex{\n    z-index: 10 !important;\n }\n.githubButton:hover {\n    color: black !important;\n}\n\n.githubButtonSize {\n    font-size: 50px;\n    color: white;\n    margin-right: 10px;\n}\n\n/* LinkedIn Icon */ \n.linkedInButton:hover{\n    color: #0077b5 !important;\n    background-size: cover;\n}\n\n.linkedInButtonSize {\n    font-size: 50px; \n    color: white; \n    margin-right: 10px;\n}\n\n/* Navigation elements underline*/\n.navElements:hover{ \n    text-decoration: underline !important;\n    text-decoration-color: white !important;\n    text-underline-offset: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

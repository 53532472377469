import React from 'react'

import styles from './style.module.css'

/**
 * Adds a horizontal line into the middle of a bubble container
 * @returns 
 */
export default function HorizontalLine()
{
	return <div className={styles.Hline}></div>
}
const request = options => {
    const headers = new Headers({
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin":"*"
    });
  
    if (localStorage.getItem("accessToken")) {
      headers.append("Authorization", "Bearer " + localStorage.getItem("accessToken"));
    }
  
    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
  
    return fetch(options.url, options).then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
    );
  };
  
  export function getCurrentUser() {
    if (!localStorage.getItem("accessToken")) {
      return Promise.reject("No access token set.");
    }

    console.log("React App Backend Base URL: "); 
    console.log(process.env.REACT_APP_BACKEND_BASE_URL); 

    console.log("Access token."); 
    console.log(localStorage.getItem("accessToken")); 

    return request({
      url:  process.env.REACT_APP_BACKEND_BASE_URL + "profile",
      method: "GET"
    });
  }
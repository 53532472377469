// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_VerticalLineStyle__vcMg-
{
	width: 1vmin;
	height: 25vmin;
	position: absolute;
	z-index: 0;
	background: var(--bubble-background);
	animation: style_created__BgpIp 0.4s;
	-webkit-animation: style_created__BgpIp 0.4s;
}

.style_EvenLineStyle__BB94m
{
	transform: translate(0, 50%);
	-webkit-transform: translate(0, 50%);
	-moz-transform: translate(0, 50%);
	-ms-transform: translate(0, 50%);
	-o-transform: translate(0, 50%);
}

.style_OddLineStyle__lIhMh
{
	transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
}

@keyframes style_created__BgpIp {
	0% {
		height: 0;
	}
	50% {
		height: 0;
	}
	100% {
		height: 25vmin;
	}
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/timeline/bubbles/atoms/vertical_line/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,YAAY;CACZ,cAAc;CACd,kBAAkB;CAClB,UAAU;CACV,oCAAoC;CACpC,oCAAuB;CACvB,4CAA+B;AAChC;;AAEA;;CAEC,4BAA4B;CAC5B,oCAAoC;CACpC,iCAAiC;CACjC,gCAAgC;CAChC,+BAA+B;AAChC;;AAEA;;CAEC,6BAA6B;CAC7B,qCAAqC;CACrC,kCAAkC;CAClC,iCAAiC;CACjC,gCAAgC;AACjC;;AAEA;CACC;EACC,SAAS;CACV;CACA;EACC,SAAS;CACV;CACA;EACC,cAAc;CACf;AACD","sourcesContent":[".VerticalLineStyle\n{\n\twidth: 1vmin;\n\theight: 25vmin;\n\tposition: absolute;\n\tz-index: 0;\n\tbackground: var(--bubble-background);\n\tanimation: created 0.4s;\n\t-webkit-animation: created 0.4s;\n}\n\n.EvenLineStyle\n{\n\ttransform: translate(0, 50%);\n\t-webkit-transform: translate(0, 50%);\n\t-moz-transform: translate(0, 50%);\n\t-ms-transform: translate(0, 50%);\n\t-o-transform: translate(0, 50%);\n}\n\n.OddLineStyle\n{\n\ttransform: translate(0, -50%);\n\t-webkit-transform: translate(0, -50%);\n\t-moz-transform: translate(0, -50%);\n\t-ms-transform: translate(0, -50%);\n\t-o-transform: translate(0, -50%);\n}\n\n@keyframes created {\n\t0% {\n\t\theight: 0;\n\t}\n\t50% {\n\t\theight: 0;\n\t}\n\t100% {\n\t\theight: 25vmin;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VerticalLineStyle": `style_VerticalLineStyle__vcMg-`,
	"created": `style_created__BgpIp`,
	"EvenLineStyle": `style_EvenLineStyle__BB94m`,
	"OddLineStyle": `style_OddLineStyle__lIhMh`
};
export default ___CSS_LOADER_EXPORT___;

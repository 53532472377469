import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import ATT from '../../../assets/Employers/AT&T.jpg';
import Apexon from '../../../assets/Employers/Apexon.png';
import BCO from '../../../assets/Employers/BlueCollarObjects.jpg';
import FlagstarBank from '../../../assets/Employers/FlagstarBank.jpg';
import GDIT from '../../../assets/Employers/GDIT.jpg';
import GeorgeMasonUniversity from '../../../assets/Employers/GeorgeMasonUniversity.png';
import GoldmanSachs from '../../../assets/Employers/GoldmanSachs.png';
import IBMGBS from '../../../assets/Employers/IBMGBS.png';
import SVATS from '../../../assets/Employers/SVATS.jpg';

import './employers.css';

const Employers = () => {

    const employersData = [
        {
            id: "employer1",
            employer: "IBM GBS",
            image: {IBMGBS}
        }, 
        {
            id: "employer2", 
            employer: "AT & T", 
            image: {ATT}
        }, 
        {
            id: "employer3", 
            employer: "Blue Collar Objects, LLC", 
            image: {BCO}
        }, 
        {
            id: "employer4", 
            employer: "General Dynamics and Information Technology", 
            image: {GDIT}
        }, 
        {
            id: "employer5", 
            employer: "SVATS", 
            image: {SVATS}
        }, 
        {
            id: "employer6", 
            employer: "Apexon", 
            image: {Apexon}
        }, 
        {
            id: "employer7", 
            employer: "Goldman Sachs.", 
            image: {GoldmanSachs}
        }, 
        {
            id: "employer8", 
            employer: "Flagstar Bank.", 
            image: {FlagstarBank}
        }, 
        {
            id: "employer9", 
            employer: "George Mason University.", 
            image: {GeorgeMasonUniversity}
        }
    ]
    

    return (
        <Box component={Paper}>
        <Box id="employers" style={{height: "680px"}}>
         <br />
          <Typography variant='h6' align="center" style={{color: '#484848'}}>
            EMPLOYERS
          </Typography>
            <table align="center">
                <tbody >
                    <tr >
                        <td align='center'>
                        <img id="employer1" src={IBMGBS}/>
                        </td>
                        <td align='center'>
                            <img id="employer2" src={ATT}/>
                        </td>
                        <td align='center'>
                            <img id="employer3" src={GeorgeMasonUniversity}/>
                        </td>
                        <td align='center'>
                            <img id="employer4" src={BCO}/>
                        </td>
                    </tr>
                    <tr>
                        <td align='center'>
                            <img id="employer5" src={GDIT}/>
                        </td>
                        <td align='center' >
                            <img id="employer6" src={SVATS}/>
                        </td>
                        <td align='center'>
                            <img id="employer7" src={Apexon}/>
                        </td>
                        <td align='center'>
                            <img id="employer9" src={GoldmanSachs}/>
                        </td>
                        <td align='center'>
                            <img id="employer8" src={FlagstarBank}/>
                        </td>
                    </tr>
                    <tr>
                    
                    </tr>
                </tbody>
            </table>


{/* 
            <TableContainer>
        <Table >
            <TableBody>
                <TableRow>
                    <TableCell>
                        <img id="employer1" src={IBMGBS}/>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        
                    </TableCell>
                    <TableCell>

                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </TableContainer> */}
        </Box>
        </Box>
    )
}

export default Employers; 
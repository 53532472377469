import React from 'react';

import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import ModalDialog from '@mui/joy/ModalDialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import styles from './style.module.css';
/**
 * Adds a floating block with text over or under a bubble
 * @param {Object} props 
 * @param {String} props.identifier - bubble identifier of the relative commented bubble
 * @param {String} props.comment - the state is gonna define the value of the textarea or
 * the image base64
 * @param {Boolean} props.isImage - if the comment state contains an image
 * @param {Function(identifier: int, data: Object)} props.updateBubble - will be called
 * whenever the user updates the content of a comment of a bubble
 * @returns 
 */
export default function Comment(props) {
	const [image, setImage] = useState(null)

	/**
	 * Used to define the value of the textarea or image whenever the user opens a file
	 */
	const { comment, isImage, certsAndAwardsData } = props

	const textareaRef = useRef(undefined)
	useEffect(() => {
		const handleChange = () => {
			if (isImage)
				setImage(comment)
			else if(textareaRef.current && comment !== textareaRef.current.innerHTML)
				textareaRef.current.innerHTML = comment
		}
		handleChange()
	}, [comment, isImage])

	/** Initializes the textarea with the initial value of comment */
	useEffect(() => {
		if (textareaRef && comment)
			textareaRef.current.innerHTML = comment
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	/** Updates the bubble comment if an image is added */
	const { identifier, updateBubble } = props
	useEffect(() => {
		if (image !== null)
			updateBubble(identifier, { 
				isImage: image !== '', 
				comment: image 
			})
	}, [image]) // eslint-disable-line react-hooks/exhaustive-deps

	/**
	 * Updates bubbles when the textarea is changed
	 * @param {Object} ev 
	 */
	function handleTextChange(ev) {
		props.updateBubble(props.identifier, { isImage: false, comment: ev.target.innerHTML })
	}

	const [openModals, setOpenModals] = useState(false);

    const openModal = (modalId) => {
        setOpenModals((prevOpenModals) => ({ ...prevOpenModals, [modalId]: true }));
    };

    const closeModal = (modalId) => {
        setOpenModals((prevOpenModals) => ({ ...prevOpenModals, [modalId]: false }));
    };

	const modalContentStyle = {
		width: '700px',
		overflow: 'scroll'
	}

	const certificateStyle = {
		overflow: 'scroll', 
	}

	return (
		<div className={styles.CommentStyle + " disable-anim " +
			(props.isEven ? styles.EvenCommentStyle : styles.OddCommentStyle)}
			data-testid="comment">
			{!image ?
				<div ref={textareaRef}
					className={styles.TextareaStyle}
					data-testid="comment_textarea"
					onInput={handleTextChange}
					contentEditable={true}
					spellCheck={false}
					role="textbox"></div> :
					<>
					<img src={image} className={styles.ImageStyle}
					data-testid="comment_image"
					alt="Bubble"
					onClick={() => openModal(certsAndAwardsData.modalName)}></img>
                    <Modal
                        open={openModals[certsAndAwardsData.modalName] == null ? false : openModals[certsAndAwardsData.modalName]}
                        onClose={() => closeModal(certsAndAwardsData.modalName)}>
                        <ModalDialog layout="center" sx={certsAndAwardsData.isCertificate?modalContentStyle:certificateStyle}>
                            <ModalClose />
                            <Typography align="center">{certsAndAwardsData.modalTitle}</Typography>
                            <Box
                                component="img"
                                src={certsAndAwardsData.modalContentImage}
                            />
                        </ModalDialog>
                    </Modal>
					</>
			}
		</div>
	)
}
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home ul
{
    display: block;
    margin: auto;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 20px;
    margin-right: 40px;
}

#homeIntro
{
    color: white;
}

#homeIntroImage
{
    border-radius: 10px;
    border: 3px solid white; 
}

#homeIntro2
{
    color: white; 
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/home/home.css"],"names":[],"mappings":"AAAA;;IAEI,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;;IAEI,YAAY;AAChB;;AAEA;;IAEI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;;IAEI,YAAY;AAChB","sourcesContent":["#home ul\n{\n    display: block;\n    margin: auto;\n    text-align: center;\n    color: white;\n    font-weight: 500;\n    font-size: 20px;\n    margin-right: 40px;\n}\n\n#homeIntro\n{\n    color: white;\n}\n\n#homeIntroImage\n{\n    border-radius: 10px;\n    border: 3px solid white; \n}\n\n#homeIntro2\n{\n    color: white; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_ContainerStyle__MZ4eP
{
	/* background-color: var(--background); */
	background-color: #484848;
	transition: 0.5s background-color;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: row;

	width: 100%;
	height: 100%;
	position: relative;
}

.style_ContainerStyle__MZ4eP div
{
	margin: 0 1vw 0 1vw;
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/timeline/bubbles/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,yCAAyC;CACzC,yBAAyB;CACzB,iCAAiC;;CAEjC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,cAAc;;CAEd,WAAW;CACX,YAAY;CACZ,kBAAkB;AACnB;;AAEA;;CAEC,mBAAmB;AACpB","sourcesContent":[".ContainerStyle\n{\n\t/* background-color: var(--background); */\n\tbackground-color: #484848;\n\ttransition: 0.5s background-color;\n\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tflex-flow: row;\n\n\twidth: 100%;\n\theight: 100%;\n\tposition: relative;\n}\n\n.ContainerStyle div\n{\n\tmargin: 0 1vw 0 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerStyle": `style_ContainerStyle__MZ4eP`
};
export default ___CSS_LOADER_EXPORT___;

import * as React from 'react';
import BlogBody from "./components/content/BlogBody";

const Blog = () => {
    
    return (
        <>
            <BlogBody/>
        </>
    ); 
}

export default Blog; 
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#profCertsAwards
{
    border-radius: '10px';
    background:#484848;
}

/* #profCertsAwardsDiv
{
    height: '350px';
   background-color: 'black';
   overflow: hidden;
} */

#profCertsAwardsDiv
{
    
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #484848;
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/profCertsAndAwards/certsAndAwards.css"],"names":[],"mappings":"AAAA;;IAEI,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;;;;;GAKG;;AAEH;;;IAGI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":["#profCertsAwards\n{\n    border-radius: '10px';\n    background:#484848;\n}\n\n/* #profCertsAwardsDiv\n{\n    height: '350px';\n   background-color: 'black';\n   overflow: hidden;\n} */\n\n#profCertsAwardsDiv\n{\n    \n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #484848;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

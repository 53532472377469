import React, { useEffect, useState } from 'react';
import { getCurrentUser } from './APIUtils';
import { useAuth } from './AuthContext';

  
// const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
//     <Route
//       {...rest}
//       render={props =>
//         authenticated ? (
//           <Component {...rest} {...props} />
//         ) : (
//           <Navigate
//             to={{
//               pathname: 'http://localhost:8080/',
//               state: { from: props.location }
//             }}
//           />
//         )
//       }
//     />
// );

const PrivateRoute = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(); 
    const [loaded, setIsLoaded] = useState(false); 

    const {isLoggedIn} = useAuth(); 

    console.log("Authentication value: "); 
    console.log(isLoggedIn); 

    const RedirectUrl = ({ url }) => {
        useEffect(() => {
          window.location.href = url;
        }, [url]);
      
        return <h5>Redirecting...</h5>;
      };

    useEffect(()=> {
  
        if (localStorage.getItem("accessToken")) {
                  getCurrentUser().then(data => {
  
        console.log("Current User."); 
        setCurrentUser(data); 
        console.log(data); 
        let something = data.id != null; 
        console.log(something);
        setIsLoaded(true)
                  })}
      else
      {
        setIsLoaded(true); 
      }
    }, []); 

    
    // return ( loaded && ((currentUser&&currentUser.id != null) ? <>{children}</> : <RedirectUrl url={process.env.REACT_APP_BACKEND_BASE_URL} />));

    return (isLoggedIn ? <>{children}</>:<RedirectUrl url={process.env.REACT_APP_BACKEND_BASE_URL}/>);
  }

export default PrivateRoute
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayTextNextToAvatar {
    margin: 15px;
}

.avatar {
    float: left;
    margin: 5px;
}

.readMore {
    clear: both;
    text-align: justify;
    font-size: 25px;
}

#left, #right { 
    display: table-cell; 
    justify-self: middle;
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/testimonials/testimonials.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[".displayTextNextToAvatar {\n    margin: 15px;\n}\n\n.avatar {\n    float: left;\n    margin: 5px;\n}\n\n.readMore {\n    clear: both;\n    text-align: justify;\n    font-size: 25px;\n}\n\n#left, #right { \n    display: table-cell; \n    justify-self: middle;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;


console.log("React App Backend Base URL: "); 
console.log(process.env.REACT_APP_BACKEND_BASE_URL); 

const BASE_API_URL = process.env.REACT_APP_BACKEND_BASE_URL + "blogOps"; 

export const BlogAPI = {

    getArticlesByYearAndType: async(year, type) => {
        const path = BASE_API_URL +"/articles?year=" + year + "&type=" + type; 
        const res = await (await fetch(path)).json(); 

        return res; 
    }, 

    getArticleById: async(articleId) => {
        const path = BASE_API_URL + "/article?articleId=" + articleId; 
        const res = await (await (fetch(path))).json(); 

        return res; 
    }, 

    getDistinctYears: async(articleType) => {
        const path = BASE_API_URL + "/distinctYears?articleType=" + articleType; 
        const res = await (await fetch(path)).json(); 

        return res; 
    }, 

    addArticle: async(requestOptions) => {
        const path = BASE_API_URL + "/article"; 
        const res = await (await fetch(path, requestOptions)).json(); 

        return res; 
    }, 

    updateArticle: async(requestOptions) => {
        const path = BASE_API_URL + "/article"; 
        const res = await (await fetch(path, requestOptions)).json(); 

        return res; 
    }, 

    deleteArticle: async(articleId) => {
        const requestOptions = {method: "DELETE"}; 
        const path = BASE_API_URL + "/article?articleId=" + articleId; 
        const res = await (await fetch(path, requestOptions)); 

        return res; 
    }
}; 
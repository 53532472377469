import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import Carousel from 'react-material-ui-carousel';
import testimonialGMU from '../../../assets/Testimonials/FerasBatraseh.jpg';
import testimonialIBM from '../../../assets/Testimonials/JianHongHu.jpg';
import testimonialGMU2 from '../../../assets/Testimonials/JohnBono.jpg';
import ReadMore from './ReadMore';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Import css. 
import './testimonials.css';

/**
 * TODO: Eventually this would be convereted to carousel of 2 column tables. 
 */
const Testimonials = () => {

  const testimonialData = [
    {
      id: 1,
      testimonialAvatarImage: { testimonialGMU },
      testimonialDescription: "Besides being in one of my UX design and development graduate classes (SWE 632), Abhishek was an active author on one research project that was then published online with Predictive Analytics Times: http://www.predictiveanalyticsworld.com/patimes/mind-text-public-data-political-insights/9125/ When Abhishek was in class, he continuously illustrated high interest, hard work, good team spirit, and dedication. Abhishek has been a creative member of his project team; he also presented the work done in a very clear manner - any software team would be lucky to have him.",
      testimonialDescriptionMore: "Besides being in one of my UX design and development graduate classes (SWE 632), Abhishek was an active author on one research project that was then published online with Predictive Analytics Times: http://www.predictiveanalyticsworld.com/patimes/mind-text-public-data-political-insights/9125/ When Abhishek was in class, he continuously illustrated high interest, hard work, good team spirit, and dedication. Abhishek has been a creative member of his project team; he also presented the work done in a very clear manner - any software team would be lucky to have him."
    }, 
    {
      id: 2,
      testimonialAvatarImage: { testimonialIBM },
      testimonialDescription: "Abhishek is a smart, dedicate and detail-oriented software engineer. He has been a good team player and produced great results for the project team.",
      testimonialDescriptionMore: "Abhishek is a smart, dedicate and detail-oriented software engineer. He has been a good team player and produced great results for the project team. "
    }, 
    {
      id: 3, 
      testimonialAvatarImage: {}, 
      testimonialDescription: "Abhishek served as a Graduate Teaching Assistant for me for courses in Introductory Problem-Solving Using Computer Programming (Java) and Object-Oriented Techniques in Java. During this time, it was a joy working with him. He was knowledgeable in the course content and he was very proactive in requesting feedback, making necessary adjustments. I would re-hire him again without any doubt. Any organization that hires him would see a positive impact from his cheerful demeanor, strong knowledge, and good work ethic.", 
      testimonialDescriptionMore: "Abhishek served as a Graduate Teaching Assistant for me for courses in Introductory Problem-Solving Using Computer Programming (Java) and Object-Oriented Techniques in Java. During this time, it was a joy working with him. He was knowledgeable in the course content and he was very proactive in requesting feedback, making necessary adjustments. I would re-hire him again without any doubt. Any organization that hires him would see a positive impact from his cheerful demeanor, strong knowledge, and good work ethic."
    }
  ]

  return (
    <Box id="testimonials" component={Paper}>
      <br />
          <Typography variant='h6' align="center" style={{color: '#484848'}}>
            TESTIMONIALS
          </Typography>
      <Carousel 
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        indicators={false}
        navButtonsAlwaysVisible={true}
        interval={5000}
        duration={500}>

        {/* First set of carousel components */}
        <div align="center" style={{ height: "275px", justifyContent: "middle" }}>
          <div id="left" style={{ padding: '20px' }}>
           
            <Avatar
              className="avatar"
              src={testimonialGMU}
              sx={{ width: 156, height: 156, border: '3px solid green', marginRight: '25px', marginTop: '25px' }}>
               
              { testimonialData[0].testimonialDescription}
            </Avatar>
            <p style={{float: "left"}}> {"Dr. Feras Batarseh, Ph.D"}</p>
            <br /> 
            <br/>
            <ReadMore className="readMore" >
              {testimonialData[0].testimonialDescriptionMore}
            </ReadMore>
          </div>

          <div id="right">
            <Avatar
              className="avatar"
              src={testimonialIBM}
              sx={{ width: 156, height: 156, border: '3px solid blue', marginRight: '25px', marginTop: '25px' }}>
            </Avatar>
            <p style={{float: "left"}}> {"Dr. Jianhong Hu, Ph.D"}</p>
            <br /> 
            <br/>
            <p className="text" style={{width: '750px', textAlign: 'justify'}}>
              {testimonialData[1].testimonialDescriptionMore}
            </p>
          </div>
        </div>

        {/* Second set of carousel components */}
        <div align="center" style={{ height: "275px", justifyContent: "middle" }}>
          <div id="left" style={{ padding: '20px' }}>
            <Avatar
              className="avatar"
              src={testimonialGMU2}
              sx={{ width: 156, height: 156, border: '3px solid red', marginRight: '25px', marginTop: '25px' }}>
              {testimonialData[2].testimonialDescription}
            </Avatar>
            <p style={{float: "left"}}> {"Dr. John Bono, Ph.D"}</p>
            <br /> 
            <br/>
            <ReadMore className="readMore" >
              {testimonialData[2].testimonialDescriptionMore}
            </ReadMore>
          </div>
        </div>
      </Carousel>

     
    </Box>
  );
};

export default Testimonials;
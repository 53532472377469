import React, { useEffect, useRef } from "react";

// Import styles. 
import './Editor.css';

const PreviewPost = (props) => {

    const refd = useRef(null); 

    useEffect(() => {
        refd.current.innerHTML = props.content;
    }, [props.content])

    
    return (
        <>
            <div style={{
                maxWidth: '980px',
                backgroundColor: 'white',
                wordWrap: "break-word",
                borderRadius: "10px",
                padding: "10px"
            }}
                className="editor-container" ref={refd}>
            </div>
        </>
    )
}

export default PreviewPost; 
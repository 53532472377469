// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_ContainerStyle__M733Y
{
	width: 100vw;
	/* height: 100vh; */
	height: 680px;
	transform: scale(0.90);
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/templates/editor/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,YAAY;CACZ,mBAAmB;CACnB,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".ContainerStyle\n{\n\twidth: 100vw;\n\t/* height: 100vh; */\n\theight: 680px;\n\ttransform: scale(0.90);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerStyle": `style_ContainerStyle__M733Y`
};
export default ___CSS_LOADER_EXPORT___;

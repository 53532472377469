// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_HorizontalLineStyle__vy2-f
{
	width: 100vw;
	height: 10px;
	/*height: 1vmin;
	*/position: absolute;
	/*top: calc(50vh - 0.5vmin);
	*/
	top: 50%;
	left: 0;
	right: 0;
	margin: 0 !important;
	
	background: var(--bubble-background);
	z-index: 111;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
}
.style_Hline__rbAUf{
width: 100%;
height: 10px;
position: absolute;
top: 50%;
left: 0;
right: 0;
margin: 0 !important;
z-index: 0;
background: var(--bubble-background);
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/timeline/bubbles/atoms/horizontal_line/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,YAAY;CACZ,YAAY;CACZ;EACC,CAAC,kBAAkB;CACpB;EACC;CACD,QAAQ;CACR,OAAO;CACP,QAAQ;CACR,oBAAoB;;CAEpB,oCAAoC;CACpC,YAAY;CACZ,2BAA2B;CAC3B,mCAAmC;CACnC,gCAAgC;CAChC,+BAA+B;CAC/B,8BAA8B;AAC/B;AACA;AACA,WAAW;AACX,YAAY;AACZ,kBAAkB;AAClB,QAAQ;AACR,OAAO;AACP,QAAQ;AACR,oBAAoB;AACpB,UAAU;AACV,oCAAoC;AACpC","sourcesContent":[".HorizontalLineStyle\n{\n\twidth: 100vw;\n\theight: 10px;\n\t/*height: 1vmin;\n\t*/position: absolute;\n\t/*top: calc(50vh - 0.5vmin);\n\t*/\n\ttop: 50%;\n\tleft: 0;\n\tright: 0;\n\tmargin: 0 !important;\n\t\n\tbackground: var(--bubble-background);\n\tz-index: 111;\n\ttransform: translateY(-50%);\n\t-webkit-transform: translateY(-50%);\n\t-moz-transform: translateY(-50%);\n\t-ms-transform: translateY(-50%);\n\t-o-transform: translateY(-50%);\n}\n.Hline{\nwidth: 100%;\nheight: 10px;\nposition: absolute;\ntop: 50%;\nleft: 0;\nright: 0;\nmargin: 0 !important;\nz-index: 0;\nbackground: var(--bubble-background);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HorizontalLineStyle": `style_HorizontalLineStyle__vy2-f`,
	"Hline": `style_Hline__rbAUf`
};
export default ___CSS_LOADER_EXPORT___;

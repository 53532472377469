import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Button } from '@mui/material';
import {
    ThemeProvider,
    createTheme
} from "@mui/material/styles";
import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './scrollableYears.css';

const getArticleTypes = () =>
    ["JAVA", "REACT", "KAFKA", "OAUTH", "DOCKER"]


const ScrollableArticleTypes = ({ data: parentDataMutatorForArticleScrolls }) => {
    
    // Custom theme provider for the 'blog'. 
    const { palette } = createTheme();
    const { augmentColor } = palette;
    const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
    const theme = createTheme({
        palette: {
            blog: createColor('#484848'),
        },
    });

    const [articleTypes] = React.useState(getArticleTypes);
    const [selectedStates, setSelectedStates] = useState(Array(articleTypes.length).fill(false));

    
    useEffect(() => {
        // Re-render component on state change. 
    }, [selectedStates]);


    /**
     * Function to ensure that the selected article type is highlighted on the blog. 
     * And when the article type is selected, its value is propagated back to the 
     * parent component. 'BlogBody'. 
     * 
     * @param {String} articleType 
     * @param {Number} index 
     */
    function displayArticles(articleType, index) {
        // Ensure that the selected article type is highlighted on the Blog. 
        let falseArray = Array(articleTypes.length).fill(false);
        falseArray[index] = true;
        setSelectedStates(falseArray);

        // When article type is selected, propagate the value to the parent. 
        parentDataMutatorForArticleScrolls.changeArticleType(articleType);
        
        // Clear selection. 
        let falseArrayTwo = Array(1).fill(false);  
        parentDataMutatorForArticleScrolls.updateArticleTypeSelection(falseArrayTwo); 
    };


    const ArrowLeft = () => {

        const visibility = React.useContext(VisibilityContext);

        return (
            <ArrowBackIosNewSharpIcon key="scrollableArticleTypesBack" className="material-icons" fontSize="small" onClick={() => visibility.scrollPrev()} />
        )
    }

    const ArrowRight = () => {

        const visibility = React.useContext(VisibilityContext);

        return (
            <ArrowForwardIosSharpIcon key="scrollableArticleTypesForwared" className="material-icons" fontSize="small" onClick={() => visibility.scrollNext()} />
        )
    }


    return (
        <ScrollMenu key="scrollableArticleTypes" style={{ width: '100px' }} LeftArrow={ArrowLeft} RightArrow={ArrowRight}>
            {articleTypes.map((articleType, index) => (
                <ThemeProvider key={"scrollableArticleTypesThemeProvider"+index} theme={theme}>
                    <Button
                        color="blog"
                        title={articleType}
                        key={index + "scrollableArticleTypes"}
                        onClick={() => displayArticles(articleType, index)}
                        variant={selectedStates[index] ? "contained" : "text"}>
                        {articleType}
                    </Button>
                </ThemeProvider>
            ))}
        </ScrollMenu>
    )
}

export default ScrollableArticleTypes; 
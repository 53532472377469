// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#employers
{
    transform: scale(0.80);
}
#employer1
{
    height: 250px; 
    width: 250px; 
    transform: scale(0.75);
    padding: 20px;

}

#employer2
{
    height: 150px; 
    width: 150px; 
    border-radius: 50%;
    padding: 20px;

}

#employer3
{
    height: 150px; 
    width: 250px;
    border-radius: 10px;

}

#employer5
{
    height: 100px; 
    width: 350px; 
    padding: 20px;
}

#employer6
{
    padding: 20px;
}

#employer7
{
    padding: 20px;
}

#employer9
{
    height: 225px; 
    width: 200px; 
    border-radius: 20px;
    padding: 10px;

}

#employer8
{
    height: 150px; 
    width: 300px; 
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/portfolio/components/employers/employers.css"],"names":[],"mappings":"AAAA;;IAEI,sBAAsB;AAC1B;AACA;;IAEI,aAAa;IACb,YAAY;IACZ,sBAAsB;IACtB,aAAa;;AAEjB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,aAAa;;AAEjB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,mBAAmB;;AAEvB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,aAAa;;AAEjB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,aAAa;AACjB","sourcesContent":["#employers\n{\n    transform: scale(0.80);\n}\n#employer1\n{\n    height: 250px; \n    width: 250px; \n    transform: scale(0.75);\n    padding: 20px;\n\n}\n\n#employer2\n{\n    height: 150px; \n    width: 150px; \n    border-radius: 50%;\n    padding: 20px;\n\n}\n\n#employer3\n{\n    height: 150px; \n    width: 250px;\n    border-radius: 10px;\n\n}\n\n#employer5\n{\n    height: 100px; \n    width: 350px; \n    padding: 20px;\n}\n\n#employer6\n{\n    padding: 20px;\n}\n\n#employer7\n{\n    padding: 20px;\n}\n\n#employer9\n{\n    height: 225px; \n    width: 200px; \n    border-radius: 20px;\n    padding: 10px;\n\n}\n\n#employer8\n{\n    height: 150px; \n    width: 300px; \n    padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

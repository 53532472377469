import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BlogAPI } from '../../service/BlogAPI';
import EditorVerbum from './editor/EditorVerbum';
import PreviewPost from './editor/PreviewPost';

// Import styles. 
import '../post/editor/Editor.css';

const EditPost = () => {

    
    const { state } = useLocation();
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();
    const [newEditorState, setNewEditorState] = useState();
    const { articleObj } = state;
    const [values, setValues] = useState({
        id: articleObj.articleId,
        title: articleObj.articleTitle,
        content: articleObj.articleContent,
        category: articleObj.articleCategory,
        editorState: articleObj.articleEditorState
    });

    /**
     * function to handle article title change. 
     * 
     * @param {EVENT} event 
     */
    function handleArticleTitleChange(event) {
        setValues({ ...values, title: event.target.value });
    }

    /**
     * function to handle article title change. 
     * 
     * @param {EVENT} event 
     */
    function handleArticleTypeChange(event) {
        setValues({ ...values, type: event.target.value });
    }


    /**
     * function to update article object. 
     * 
     * @param {Article Obj} articleObj 
     */
    function editArticle(article) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body:
                JSON.stringify({
                    "article-id": article.id,
                    "article-title": article.title,
                    "article-category": article.category,
                    "article-content": article.content, 
                    "article-editorState": article.editorState
                })
        }

        // API Service call to update the article object in the database. 
        BlogAPI.updateArticle(requestOptions)
            .then(data => {
            }).catch(error => {
                console.log(error.toString());
            });
    }


    


    /**
    * Function to submit post content to the database. 
    */
    function handleOnClick() {
        // TODO: Write a validator before you attempt to add article. 

        editArticle(values);

        navigate("/Blog");
    }

    /**
     * Function to cancel the post content functionality to the database. 
     */
    function handleCancel() {
        navigate("/Blog");
    }

    /**
     * function to update the preview content of the page. 
     * 
     * @param {PreviewContent String} editorContent 
     */
    function changeContent(editorContent) {
        setValues({ ...values, content: editorContent });
    }

    /**
     * function to change the editor state of the page. 
     * 
     * @param {EditorState String} editorState 
     */
    function changeEditorState(editorState) {
        setNewEditorState(editorState);
    }


    /**
     * function to return the current editor state of the page. 
     * 
     * @returns current editor state that the articleObject (values) hold. 
     */
    function getEditorState() {
        return values.editorState;
    }

    return (
        <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#484848" }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        TECHNICAL BLOG CMS
                    </Typography>

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {/* Gap between different navigation heads and menu icon*/}
                    </Typography>

                    {/* TODO: Dynamically retrieve the url and assign it. Don't keep it static */}
                    <a className='navElements' href={process.env.REACT_APP_FRONT_END_BASE_URL}>
                        <Button color="inherit" style={{ color: "white" }} className='navElements'>HOME</Button>
                    </a>
                </Toolbar>
            </AppBar>
            <br />
            <br />
            <br />
            <br />

            <table align="center">
                <tbody>
                    <tr>
                        <td>
                            <Typography variant="h6" noWrap component="div" >
                                Title
                            </Typography>
                        </td>
                        <td>
                            Type
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <OutlinedInput
                                id="titleInput"
                                label="Title"
                                value={values.title}
                                onChange={handleArticleTitleChange}
                                style={{ backgroundColor: 'white', width: '750px', borderRadius: '10px' }}
                            />
                        </td>
                        <td>
                            {/* TODO: Get all the types. */}
                            <Select
                                id="articleTypeSelect"
                                value={values.category}
                                onChange={handleArticleTypeChange}
                                style={{ width: '250px', backgroundColor: 'white', borderRadius: '10px' }}>
                                <MenuItem value="JAVA">JAVA</MenuItem>
                                <MenuItem value="REACT">REACT</MenuItem>
                                <MenuItem value="KAFKA">KAFKA</MenuItem>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span id="titleEditSpan">
                                {formErrors.name}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <EditorVerbum setContent={changeContent} getEditorStateCustom={getEditorState} changeEditorState={changeEditorState} />


            <Typography variant="h6" noWrap component="div" align="center">
                Preview
            </Typography>

            <PreviewPost className="editor-container" content={values.content} />

            <Box textAlign='center'>
                <Button variant="contained" onClick={handleOnClick}> Edit </Button>
                &nbsp; &nbsp;
                <Button variant="contained" onClick={handleCancel}> Cancel </Button>
            </Box>
        </>
    )
}

export default EditPost; 
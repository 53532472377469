import React from 'react';
import { Navigate } from 'react-router-dom';

const OAuth2RedirectHandler = () => {

    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    function getcomponent()
    {
        const token = getUrlParameter('token');
        const error = getUrlParameter('error');

        if(token) {
            console.log("Navigating to profile."); 
            console.log(window.location); 
            localStorage.setItem("accessToken", token);

            return <Navigate replace to={{
                pathname: "/addPost",
                state: { from: window.location }
            }}/>; 
        } else {
            console.log("navigating to home. "); 
            console.log(window.location); 
            return <Navigate replace to={{
                pathname: "/",
                state: { 
                    from: window.location,
                    error: error 
                }
            }}/>; 
        }
    }
    
    return (        
        <>
        {getcomponent()}
        </>
    ); 
}

export default OAuth2RedirectHandler;
import React, { useEffect, useState } from 'react'

import Bubbles from '../../timeline/bubbles'
import CSSConfigurator from '../../timeline/css_configurator'
import styles from './style.module.css'


/**
 * Template app editor
 * @returns 
 */
export default function TemplateEditor({data: certsAndAwards})
{
	/**
	 * Data for configuring app the user defined theme
	 */
	const [theme, setTheme] = useState({
		primary: '#ffffff', 
		accent: '#212529',
		isPrinting: false})
	/**
	 * Stores bubbles data
	 */
	const [bubbles, setBubbles] = useState([])

	function handleFileOpened(content) {
		// const obj = JSON.parse(content)
		const obj = content; 
		if (obj.theme && obj.bubbles) {
			setTheme(obj.theme)
			setBubbles(obj.bubbles)
		}
		else
			alert('Incompatible or corrupted file!')
	}

	const randomJson = {
		"theme": {
			"primary": "#ffffff",
			"accent": "#212529",
			"isPrinting": false
		},
		"bubbles": [
			{
				"identifier": 1709398823431,
				"title": "2014",
				"isImage": true,
				"comment": certsAndAwards[2].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[2]
			},
			{
				"identifier": 1709398825832,
				"title": "2014",
				"isImage": true,
				"comment": certsAndAwards[1].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[1]
			},
			{
				"identifier": 4, 
				"title": "2015", 
				"isImage": true, 
				"comment": certsAndAwards[5].modalClickImage, 
				"certsAndAwardsData" : certsAndAwards[5]
			}, 
			{
				"identifier": 1709398833832,
				"title": "2015",
				"isImage": true,
				"comment": certsAndAwards[4].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[4]
			}, 
			{
				"identifier": 5, 
				"title": "2015", 
				"isImage": true, 
				"comment": certsAndAwards[0].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[0]
			}, 
			{
				"identifier": 13, 
				"title": "2022", 
				"isImage": true, 
				"comment": certsAndAwards[6].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[6]
			}, 
			{
				"identifier": 3, 
				"title": "2024", 
				"isImage": true, 
				"comment": certsAndAwards[3].modalClickImage, 
				"certsAndAwardsData": certsAndAwards[3]
			}
		]
	}
	
	useEffect(() => {
		handleFileOpened(randomJson); 
		}, []); 

	return (<main id="toPrint" className={styles.ContainerStyle}>
				<CSSConfigurator theme={theme}></CSSConfigurator>
				<Bubbles bubbles={bubbles} setBubbles={setBubbles}></Bubbles>
			</main>)
}
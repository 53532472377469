// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_CommentedBubbleStyle__T2IO2
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
 }`, "",{"version":3,"sources":["webpack://./src/portfolio/components/timeline/bubbles/molecules/commented_bubble/style.module.css"],"names":[],"mappings":"AAAA;;CAEC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB","sourcesContent":[".CommentedBubbleStyle\n{\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentedBubbleStyle": `style_CommentedBubbleStyle__T2IO2`
};
export default ___CSS_LOADER_EXPORT___;

import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Pagination from '@mui/material/Pagination';
import Stack from "@mui/material/Stack";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  ThemeProvider,
  createTheme
} from "@mui/material/styles";
import React, { useEffect, useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import Article from '../post/editor/Article';
import { BlogAPI } from '../../service/BlogAPI';
import ScrollableArticleTypes from './ScrollableArticleTypes';
import './scrollableYears.css';


const BlogBody = () => {

  // Default Constants. 
  const drawerWidth = 300;
  const emptyArticle = {
    articleTitle: "Select a valid article.", 
    articleContent: "Please select a valid article from the left panel."
  }; 
  const defaultArticleType = "JAVA"; 


  // Custom theme provider for the 'blog'. 
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });
  const theme = createTheme({
    palette: {
      blog: createColor('#484848'),
    },
  });

  // BlogBody component state constants. 
  const [articleType, setArticleType] = useState(defaultArticleType);
  const [articles, setArticles] = useState([]);
  const [selectedStates, setSelectedStates] = useState(Array(articles.length).fill(false));
  const [years, setYears] = useState([]);

  // Scrollable Years Component state constants. 
  const [selectedStatesArticleType, setSelectedStatesArticleType] = useState(Array(years.length).fill(false));
  const [selectedYear, setSelectedYear] = useState();

  // Article component state constants. 
  const [article, setArticle] = useState(emptyArticle);
  const articlesPerPage = 14;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * articlesPerPage;
  const endIndex = startIndex + articlesPerPage;

  /**
   * function to select the article from the vertical scrolling pane of articles by year. 
   * 
   * @param {Number} index points to the exact location of article for that specific year. 
   */
  function selectArticle(index)
  {
    setArticle(articles[index]); 
    const localSelectedStates = Array(articles.length).fill(false); 
    localSelectedStates[index] = true; 
    setSelectedStates(localSelectedStates); 
  }

  const articleComp = {
    articleObj : article, 
    deleteArticle: deleteArticle
  }
  /**
   * Re-renders components based on changes in value in the dependency array. 
   */
  useEffect(() => {

    // API to retrieve blog articles by 'year' and 'article type'. 
    BlogAPI.getArticlesByYearAndType(selectedYear, articleType)
      .then(data => {
        setArticles(data);
      })

    // API to retrieve distinct years for 'article type'. 
    BlogAPI.getDistinctYears(articleType)
      .then(data => {
        setYears(data);
        setSelectedYear(data[0]);
        setArticle(emptyArticle);
      })

  }, [articleType, selectedStatesArticleType]);


  /**
   * function to construct an 'Arrow Left' component for scrollable years component. 
   * 
   * @returns arrow-left component for scrollable years component. 
   */
  function ArrowLeft() {

    const visibility = React.useContext(VisibilityContext);

    return (
      <ArrowBackIosNewSharpIcon key="scrollableArticleTypesBack" className="material-icons" fontSize="small" onClick={() => visibility.scrollPrev()} />
    )
  }

  /**
   * function to construct an 'Arrow Right' component for scrollable years component. 
   * 
   * @returns arrow-right component for scrollable years component. 
   */
  function ArrowRight() {

    const visibility = React.useContext(VisibilityContext);

    return (
      <ArrowForwardIosSharpIcon key="scrollableArticleTypesForwared" className="material-icons" fontSize="small" onClick={() => visibility.scrollNext()} />
    )
  }

function deleteArticle(articleId)
{
  console.log("Deleting article."); 
  // setArticles((articles) => articles.filter((_, index) => _.articleId !== articleId)); 
  // passParentDataAndDataMutator.setFileReceiptsInParentComponent((prevReceipts) => prevReceipts.filter((_, index) => index !== postIndex));

}
  /**
   * function to display articles for that specific year. 
   * 
   * @param {String} year to display articles belonging to specific year based on article type. 
   * @param {Integer} index indicating the position of the 'year-button' in the scrollable pane. 
   */
  function displayArticlesByYear(year, index) {

    let falseArray = Array(years.length).fill(false);
    falseArray[index] = true;
    setSelectedStatesArticleType(falseArray);
    setSelectedYear(year);

    // Clear current selection when navigating between different years. 
    let falseArrayTwo = Array(articles.length).fill(false); 
    setSelectedStates(falseArrayTwo); 
  };


  // Constant to mutate data in parent from child component. (For ScrollableArticleTypes)
  const parentDataMutatorForArticleScrolls = {
    changeArticleType: setArticleType,
    updateArticleTypeSelection: setSelectedStatesArticleType,
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#484848" }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            TECHNICAL BLOG
          </Typography>

          {/* Gap between different navigation heads and menu icon*/}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

          {/* TODO: Dynamically retrieve the url and assign it. Don't keep it static */}
          <a className='navElements' href= {process.env.REACT_APP_FRONT_END_BASE_URL}>
            <Button color="inherit" style={{ color: "white" }} className='navElements'>HOME</Button>
          </a>

          <a className='navElements' href={process.env.REACT_APP_FRONT_END_BASE_URL + "addPost"}>
            <Button color="inherit" style={{ color: "white" }} className='navElements'>POST</Button>
          </a>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}>

        <Toolbar />

        <Box >
          {/*Scrollable years component to choose different years displayed based on article type selection. */}

          <ScrollMenu key="scrollableYears" LeftArrow={ArrowLeft} RightArrow={ArrowRight}>
            {years.map((year, index) => (
              <ThemeProvider key={index + "scrollableYearsThemeprovider"} theme={theme}>
                <Button
                  color="blog"
                  title={year}
                  key={year + "scrollableYears"}
                  onClick={() => displayArticlesByYear(year, index)}
                  variant={selectedStatesArticleType[index] ? "contained" : "text"}>
                  {year}
                </Button>
              </ThemeProvider>
            ))}
          </ScrollMenu>


          <List>
            {articles.slice(startIndex, endIndex).map((text, index) => (
              <ListItem key={text.articleId} disablePadding>
                <ListItemButton sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#484848",
                    color: 'white'
                  },
                  "&.Mui-focusVisible": {
                    backgroundColor: "#484848"
                  },
                  ":hover": {
                    backgroundColor: "#484848",
                    color: 'white'
                  }
                }}
                  selected={selectedStates[index]}
                  onClick={() => selectArticle(index)}
                  >
                  <ListItemText primary={text.articleTitle} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        <br />
        <br />
        <Stack spacing={2} alignItems="center">
          <Pagination align="center" count={Math.ceil(articles.length / articlesPerPage)} onChange={(newPage, index) => setCurrentPage(index)} size="small" />
        </Stack>
      </Drawer>


      {/* Component to display article based on article selection on the left panel (ScrollableYear) section. */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: "80%" }}>
        <br />
        <br />
        <ScrollableArticleTypes data={parentDataMutatorForArticleScrolls} />

        <br />
        <Article key={article.articleTitle + "article"} data={articleComp} />
        <br />
      </Box>

    </Box>
  );
}

export default BlogBody; 
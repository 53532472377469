import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';

// Professional Certification Data. 
import awsContent from '../../../assets/Certifications-Resume/Certifications/AWSCertifiedDA.jpg';
import androidContent from '../../../assets/Certifications-Resume/Certifications/Coursera Android.jpg';
import ocpjpContent from '../../../assets/Certifications-Resume/Certifications/OCPJP.jpg';
import ocwcdContent from '../../../assets/Certifications-Resume/Certifications/OW.jpg';

// Professional Certifications Badges Data. 
import androidBadge from '../../../assets/Badges/Coursera - Android Badge - Custom Made.png';
import ocwcdBadge from '../../../assets/Badges/Oracle University - Custom Badge.png';
import ocpjpBadge from '../../../assets/Badges/Oracle-Certification-badge_OC-Professional.png';
import awsBadge from '../../../assets/Badges/awsAssociateDeveloper.png';

// Awards Data. 
import apexon2022Award from '../../../assets/Certifications-Resume/Certifications/Apexon.Performance.Award.2022.jpg';
import deepskillAwardIBM from '../../../assets/Certifications-Resume/Certifications/Deep Skill.PNG';
import managerChoiceAwardIBM from '../../../assets/Certifications-Resume/Certifications/mgrChoice.PNG';

// Awards Badges Data. 
import apexon2022AwardBadge from '../../../assets/Badges/Apexon.Award.Badge.png';
import deepSkillAwardBadge from '../../../assets/Badges/Deep Skill - Full Badge.png';
import managerChoiceAwardIBMBadge from '../../../assets/Badges/Manager\'s Choice Award - Transparent - Final.png';

// Import css. 
import TemplateEditor from '../templates/editor';
import './certsAndAwards.css';

const CertsAndAwards = () => {

    // Eventually this will be retrieved from backend. 
    const certsAndAwardsData = [
        {
            id: 0,
            modalContentImage: ocpjpContent,
            modalClickImage: ocpjpBadge,
            modalTitle: 'Oracle Certified Professional Java Programmer SE 6.',
            modalName: 'OCPJPSE6', 
            isCertificate: true
        },
        {
            id: 1,
            modalContentImage: ocwcdContent,
            modalClickImage: ocwcdBadge,
            modalTitle: 'Oracle Certified Web Component Developer.',
            modalName: 'OCWCD', 
            isCertificate: true
        },
        {
            id: 2,
            modalContentImage: androidContent,
            modalClickImage: androidBadge,
            modalTitle: 'Coursera Android Application Development.',
            modalName: 'CourseraAndroid', 
            isCertificate: true
        },
        {
            id: 3,
            modalContentImage: awsContent,
            modalClickImage: awsBadge,
            modalTitle: 'Amazon Developer Associate.',
            modalName: 'AWSDA', 
            isCertificate: true
        },
        {
            id: 4,
            modalContentImage: managerChoiceAwardIBM,
            modalClickImage: managerChoiceAwardIBMBadge,
            modalTitle: 'IBM Manager\'s Choice Award.',
            modalName: 'IBMMGRAward', 
            isCertificate: false
        },
        {
            id: 5,
            modalContentImage: deepskillAwardIBM,
            modalClickImage: deepSkillAwardBadge,
            modalTitle: 'IBM Deep Skill Award.',
            modalName: 'IBMDSAward', 
            isCertificate: false
        },
        {
            id: 13,
            modalContentImage: apexon2022Award,
            modalClickImage: apexon2022AwardBadge,
            modalTitle: 'Apexon 2022 Performance Award.',
            modalName: 'ApexonAward', 
            isCertificate: true
        }
    ]


    return (
        <>
            <Box id="profCertsAwards" component={Paper}>
                <Typography variant='h6' align="center" color={'white'} paddingTop={'35px'}>
                    PROFESSIONAL CERTIFICATIONS &amp; AWARDS
                </Typography>
                
                <div id="profCertsAwardsDiv">
                    <TemplateEditor data = {certsAndAwardsData}/>
                </div>
                <br />
            </Box>
        </>
    )
}

export default CertsAndAwards; 
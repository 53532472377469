import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { BlogAPI } from '../../../service/BlogAPI';

const Article = ({ data: articleComp}) => {

    const navigate = useNavigate();

    function editPost() {
        navigate("/EditPost", { state: { articleObj: articleComp.articleObj } });
    }

    function deleteArticle()
    {
        // TODO: Check if you would have to remove it from the front-end. 
        // articleComp.deleteArticle(); 


        // Permanently delete article from the system. 
        console.log(articleComp.articleObj.articleId); 
        BlogAPI.deleteArticle(articleComp.articleObj.articleId)
            .then(() => {

                // TODO: Check if you would have to remove it from the front-end. 
                //TODO: Check if you even need to navigate. 
            navigate(0); 
        // TODO: Check and alert only if the deletion was a failure. 
        // Similarly perform error logging for other rest operations. 
      })

    //   navigate("/Blog"); 
    }

    function getPreviewContent() {
        if (isJSON(articleComp.articleObj)) {
            return JSON.parse(articleComp.articleObj.articleContent).previewContent;
        }

        return articleComp.articleObj.articleContent;
    }

    function isJSON(obj) {
        let isJsonBool = false;

        try {
            if (JSON.parse(obj.articleContent)) {
                return true;
            }
        }
        catch (e) {
            isJsonBool = false;
        }

        return isJsonBool;
    }


    return (
        <>

            <div style={{
                maxWidth: '980px',
                backgroundColor: 'white',
                wordWrap: "break-word",
                borderRadius: "10px",
                padding: "10px",
                alignSelf: "center"
            }}

                className="editor-container"
                dangerouslySetInnerHTML={{ __html: getPreviewContent() }}
            >

            </div>

            {/* Make this controlled by authentication */}
            <div style={{ maxWidth: '980px' }} align="center">
                <Button onClick={editPost}>Edit Post</Button>
                <Button onClick={deleteArticle}>Delete Post</Button>
            </div>
        </>
    )
}

export default Article; 
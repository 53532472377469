import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';

// My website components. 
import Blog from './Blog/Blog.js'; 

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AddPost from './Blog/components/post/AddPost';
import Portfolio from './portfolio/Portfolio';
import EditPost from './Blog/components/post/EditPost';
import OAuth2RedirectHandler from './Blog/oauth2/OAuth2RedirectHandler';
import PrivateRoute from './Blog/oauth2/PrivateRoute';
import { getCurrentUser } from './Blog/oauth2/APIUtils.js';
import  {AuthProvider}  from './Blog/oauth2/AuthContext.js';

const IndexApp = () => {

  function getAuthenticationValue(currentUserValue)
  {
    console.log("Current user value from index."); 
    console.log(currentUserValue); 
      if(currentUserValue.id)
      {
        return true; 
      }

      return false; 
  }

  return (
    <>
      <div>
        <Router>
          <Routes>
            <Route exact path="/" element={<Portfolio />} />
            <Route exact path="/Blog" element={<Blog />} />
            <Route exact path ="/addPostValidation" element={<OAuth2RedirectHandler/>}/>


            {/* <PrivateRoute path="/addPost" authenticated={getAuthenticationValue(currentUser)} currentUser={currentUser}
              component={AddPost}></PrivateRoute> */}
            <Route exact path="/addPost" element={
            <PrivateRoute ><AddPost/></PrivateRoute>}/>
            <Route exact path="/previewPost" element={<AddPost/>}/>
            <Route exact path="/editPost" element = {<EditPost/>}/>
          </Routes>
        </Router>
      </div>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
      <AuthProvider>

    <IndexApp />
    </AuthProvider>
  </React.StrictMode>


);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
